<template>

  <div>
    
    <MemberProfile
      v-if="isMemAuthenticated"
    />
    <div v-if="!isMemAuthenticated" class="d-flex justify-content-center mb-3 mt-32">
      <b-spinner label="Loading..."></b-spinner>
    </div>

  </div>

</template>

<style lang="scss" scoped>

</style>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { MEMBER_LOGIN, MEMBER_SET_COMPANY } from '@/core/services/store/member.auth.module';
import messageParent from '@/core/services/messageParent';
import MemberProfile from './new_member_profile/MemberProfile.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'ProfileContainer',
  mixins: [ toasts ],
  components: {
    MemberProfile
  },
  computed: {
    ...mapGetters(['isMemAuthenticated', 'currentMember','memberLoginToken']),
    
  },
  async mounted() { 
    this.route_member_id = this.$route.params.member_id;
    this.route_token = this.$route.params.token;

    // if providing a new login token, log in with another
    if (this.route_token && this.route_token.length > 8 && this.route_token !== this.memberLoginToken) {
      await this.loadClientToken();
      return;
    }
    
    if (this.isMemAuthenticated) {
      
    }
    else if (
      this.route_token !== undefined &&
      this.route_token !== null &&
      this.route_token.length > 8
    ) {
      await this.loadClientToken();
    }
    else {
      this.$router.push({ name: 'login' });
    }

  },
  data() {
    return {
      route_token: null,
      route_member_id: null,
      
    };
  },
  watch: {

  },
  methods: {

    async loadClientToken() {
      let res = await axios.post('/member/token', { login_token: this.route_token });

      if (res.status == 200) {

        this.$store.dispatch(MEMBER_LOGIN, res.data);
        this.$store.dispatch(MEMBER_LOGIN, {
          member: res.data,
          token: res.data.token,
          company: res.data.company
        });

        setTimeout(function() {
          messageParent(JSON.stringify({ height: document.body.scrollHeight }));
        }, 300);

      } else {
        console.error('error : ', res);
        this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte hämta medlemsdata');
      }
    },
  }
};
</script>
